const BannerTypes = [
  'Banner Full',
  'Banner Duplo',
  'Banner Triplo 1',
  'Banner Triplo 2',
] as const

type Props = {
  bannerType: {
    banners: typeof BannerTypes[number]
    title: {
      text: string
      textColor: string
    }
    linkUrl: string
    image: {
      desktop: string
      tablet: string
      mobile: string
    }

    images: Array<{
      title: {
        text: string
        textColor: string
      }
      linkUrl: string
      image: {
        desktop: string
        tablet: string
        mobile: string
      }
    }>
  }
}

export function formatDataCmsToComponent(data: Props) {
  if (data.bannerType.banners === 'Banner Full') {
    return {
      type: 'Banner Full',
      banners: [
        {
          linkURL: data.bannerType.linkUrl,
          image: data.bannerType.image,
          title: data.bannerType.title,
        },
      ],
    }
  }

  return {
    type: data.bannerType.banners,
    banners: data.bannerType.images.map((banner) => ({
      ...banner,
      linkURL: banner.linkUrl,
    })),
  }
}
