import { useEffect, useRef, useState } from 'react'

export const useElementOnScreen = (options: IntersectionObserverInit) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [isVisible, setIsVisible] = useState(false)
  const [hasBeenViewed, setHasBeenViewed] = useState(false)

  const callbackFunction = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries

    setIsVisible(entry.isIntersecting)
  }

  useEffect(() => {
    const currentRef = containerRef.current
    const observer = new IntersectionObserver(callbackFunction, options)

    if (currentRef) {
      observer.observe(currentRef)
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef)
      }
    }
  })

  return { containerRef, isVisible, hasBeenViewed, setHasBeenViewed }
}
