import { useCallback, useEffect } from 'react'
import classesnames from 'classnames'
import { Link } from 'gatsby'
import { Image } from 'src/components/ui/Image'
import { Container } from 'src/components/department/Container'
import { useElementOnScreen } from 'src/hooks/useElementOnScreen'
import { sendEvent } from 'src/utils/restructure/analytics'
import { makeViewPromotion } from 'src/utils/restructure/analytics/makeViewPromotion'
import { makePromoViewG3 } from 'src/utils/restructure/analytics/makePromoViewG3'
import { InView } from 'react-intersection-observer'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { getFeatureObject } from 'src/utils/amplitude/getFeatureObject'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'

import { formatDataCmsToComponent } from './utils/formatDataCmsToComponent'

export type Banner = {
  image: {
    altImage?: string
    tablet: string
    desktop: string
    mobile: string
  }
  title: {
    text: string
    textColor: 'preto' | 'branco'
  }
  linkURL: string
  position?: number
}

const BannerTypes = [
  'Banner Full',
  'Banner Duplo',
  'Banner Triplo 1',
  'Banner Triplo 2',
] as const

type Props = {
  enableSession: boolean
  banners: Banner[]
  type: typeof BannerTypes[number]
  section?: string
  locationOnPage?: string
}

const classBanner = {
  'Banner Full': {
    dataBannerType: 'Single Banner',
    mainBanner: {
      text: 'mobile-heading-title4 tablet-heading-title3 desktop-heading-title2',
      card: 'min-h-[328px] p:min-h-[394px] xs:min-h-[448px] restructure-tablet:min-h-[310px] restructure-small-desktop:min-h-[500px] row-span-2 col-span-2',
      padding: 'p-md restructure-tablet:p-lg restructure-small-desktop:p-xxl',
      imageDimension: {
        desktop: {
          width: 1280,
          height: 500,
        },
        tablet: {
          width: 794,
          height: 310,
        },
        mobile: {
          width: 328,
          height: 328,
        },
      },
    },
    lastBanner: {
      text: '',
      card: '',
      padding: '',
    },
    container: 'grid grid-cols-2 grid-rows-2 grid-flow-col gap-md',
  },
  'Banner Duplo': {
    dataBannerType: 'Four Banners',
    mainBanner: {
      text: 'mobile-body-regular-text2 tablet-heading-title5 desktop-heading-title3',
      card: 'min-h-[180px] restructure-tablet:min-h-[310px] restructure-small-desktop:min-h-[500px] restructure-tablet:min-h-[310px] restructure-small-desktop:min-h-[500px] row-span-1 col-span-2 restructure-tablet:row-span-2 restructure-tablet:col-span-1',
      padding: 'p-md restructure-tablet:p-md restructure-small-desktop:p-xl',
      imageDimension: {
        desktop: {
          width: 628,
          height: 500,
        },
        tablet: {
          width: 391,
          height: 310,
        },
        mobile: {
          width: 328,
          height: 180,
        },
      },
    },
    lastBanner: {
      text: 'mobile-body-regular-text2 tablet-heading-title5 desktop-heading-title3',
      card: 'row-span-1 col-span-2 restructure-tablet:row-span-2 restructure-tablet:col-span-1',
      padding: 'p-md restructure-tablet:p-md restructure-small-desktop:p-xl',
      imageDimension: {
        desktop: {
          width: 628,
          height: 500,
        },
        tablet: {
          width: 391,
          height: 310,
        },
        mobile: {
          width: 328,
          height: 180,
        },
      },
    },
    container:
      'grid grid-cols-2 grid-rows-2 grid-flow-row restructure-tablet:grid-flow-col gap-sm restructure-small-desktop:gap-lg',
  },
  'Banner Triplo 1': {
    dataBannerType: 'Four Banners',
    mainBanner: {
      text: 'mobile-body-regular-text2 tablet-heading-title5 desktop-heading-title3',
      card: 'min-h-[180px] restructure-tablet:min-h-[310px] restructure-small-desktop:min-h-[500px]  row-span-1 col-span-2 restructure-tablet:row-span-2 restructure-tablet:col-span-1 restructure-small-desktop:min-h-[500px]',
      padding: 'p-md restructure-tablet:p-lg restructure-small-desktop:p-xl',
      imageDimension: {
        desktop: {
          width: 628,
          height: 500,
        },
        tablet: {
          width: 391,
          height: 310,
        },
        mobile: {
          width: 328,
          height: 180,
        },
      },
    },
    lastBanner: {
      text: 'mobile-body-regular-text3 tablet-body-regular-text2 desktop-heading-title4',
      card: 'min-h-[220px] row-span-1 col-span-1 row-start-2 restructure-tablet:row-auto max-h-[223px] restructure-small-desktop:max-h-none',
      padding: 'p-sm restructure-tablet:p-md restructure-small-desktop:p-lg',
      imageDimension: {
        desktop: {
          width: 628,
          height: 238,
        },
        tablet: {
          width: 391,
          height: 149,
        },
        mobile: {
          width: 158,
          height: 220,
        },
      },
    },
    container:
      'grid grid-cols-2 grid-rows-2 grid-flow-row restructure-tablet:grid-flow-col gap-sm restructure-small-desktop:gap-lg',
  },
  'Banner Triplo 2': {
    dataBannerType: 'Four Banners',
    mainBanner: {
      text: 'mobile-body-regular-text2 tablet-heading-title3 desktop-heading-title2',
      card: 'min-h-[180px] p:min-h-[216px] xs:min-h-[246px] restructure-tablet:min-h-[310px] restructure-small-desktop:min-h-[500px] row-span-1 col-span-2 restructure-small-desktop:min-h-[500px]',
      padding: 'p-md restructure-tablet:p-lg restructure-small-desktop:p-xxl',
      imageDimension: {
        desktop: {
          width: 1280,
          height: 500,
        },
        tablet: {
          width: 794,
          height: 310,
        },
        mobile: {
          width: 328,
          height: 180,
        },
      },
    },
    lastBanner: {
      text: 'mobile-body-regular-text3 tablet-body-regular-text1 desktop-heading-title4',
      card: 'max-w-[158px] min-w-full min-h-[200px] row-span-1 col-span-1 row-start-2 pp:min-h-[220px] p:min-h-[260px] xs:min-h-[300px] restructure-small-desktop:min-w-none restructure-small-desktop:min-h-[278px] restructure-small-desktop:max-h-none restructure-small-desktop:max-w-none restructure-medium-desktop:min-h-[360px]',
      padding: 'p-sm restructure-tablet:p-md restructure-small-desktop:p-lg',
      imageDimension: {
        desktop: {
          width: 628,
          height: 360,
        },
        tablet: {
          width: 391,
          height: 223,
        },
        mobile: {
          width: 158,
          height: 220,
        },
      },
    },
    container:
      'grid grid-cols-2 grid-rows-[_1fr,_auto] grid-flow-row gap-md restructure-small-desktop:gap-lg',
  },
} as any

const token = {
  text: {
    branco: 'text-restructure-primary-inverted',
    preto: 'text-restructure-primary',
  },
  background: {
    branco: 'border-restructure-border-primary',
    preto: 'border-restructure-border-primary-inverted',
  },
} as any

export default function GridBanner(props: Props) {
  const { banners, type } = formatDataCmsToComponent(props as any)
  const { section, locationOnPage } = props

  const standardObject = getStandardObject()

  const { containerRef, isVisible, hasBeenViewed, setHasBeenViewed } =
    useElementOnScreen({
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    })

  const handleClickBanner = useCallback((banner: Banner, index: number) => {
    if (!locationOnPage || !section) {
      return
    }

    const featureObject = getFeatureObject({
      section,
      locationOnPage,
      name: banner?.image?.altImage ?? '',
    })

    dispatchAmplitudeEvent({
      eventName: 'Promotional Element Selected',
      eventData: {
        ...standardObject,
        ...featureObject,
        'promotional element name': banner?.title?.text ?? '',
        'promotional element index': index ?? 0,
      },
    })

    if (window === null) {
      return
    }

    window.dataLayer = window?.dataLayer ?? []

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'promotionClick',
      ecommerce: {
        promoClick: {
          promotions: [
            {
              id: banner.linkURL,
              name: banner.title.text,
              creative: banner.image.desktop,
              position: `slot${banner.position}`,
            },
          ],
        },
      },
    })

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'select_promotion',
      ecommerce: {
        promotion_id: banner.linkURL,
        promotion_name: banner.title.text,
        creative_slot: banner.image.desktop,
        location_id: 'hero_banner',
        index: banner.position,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sendPromoFeatureViewedEvent = ({
    image,
    title,
  }: {
    image: { altImage: string }
    title: { text: string }
  }) => {
    if (!props.locationOnPage || !props.section) {
      return
    }

    const eventData = {
      ...getStandardObject(),
      ...getFeatureObject({
        section: props.section,
        locationOnPage: props.locationOnPage,
        name: image?.altImage ?? title?.text ?? '',
      }),
    }

    dispatchAmplitudeEvent({ eventName: 'Promo Feature Viewed', eventData })
  }

  const formatBanners = {
    'Banner Full': [banners[0]],
    'Banner Duplo': banners.slice(0, 2),
    'Banner Triplo 1': banners,
    'Banner Triplo 2': banners,
  } as any

  const bannerSelect = formatBanners[type]
  const classBannerSelect = classBanner[type]

  useEffect(() => {
    if (!isVisible || hasBeenViewed) {
      return
    }

    const promotions = bannerSelect.map(
      ({ title, linkURL, image }: any, index: number) => {
        return {
          promotion_id: linkURL,
          promotion_name: title.text,
          creative_slot: image.desktop,
          index: index + 1,
        }
      }
    )

    sendEvent(makePromoViewG3(promotions))
    sendEvent(makeViewPromotion(promotions))
    setHasBeenViewed(true)
  }, [isVisible, hasBeenViewed, setHasBeenViewed, bannerSelect])

  if (!props.enableSession) {
    return <></>
  }

  return (
    <div ref={containerRef}>
      <Container
        ref={containerRef}
        className={classesnames(
          'my-[64px] restructure-tablet:my-[56px] restructure-small-desktop:my-[80px]',
          classBannerSelect.container
        )}
      >
        {bannerSelect.map(({ image, title, linkURL }: any, index: number) => (
          <InView
            as="div"
            key={`${title.text}-${index}`}
            triggerOnce
            threshold={0.7}
            onChange={(inView) => {
              if (inView) {
                sendPromoFeatureViewedEvent({ image, title })
              }
            }}
            className={classesnames(
              `relative flex-1 rounded-2xl  bg-restructure-background-primary flex items-end`,
              { [`${classBannerSelect.mainBanner.card}`]: index === 0 },
              { [`${classBannerSelect.lastBanner.card}`]: index > 0 }
            )}
            data-testid="container-banner-grid"
          >
            <Link
              to={linkURL ?? ''}
              onClick={() =>
                handleClickBanner(
                  {
                    image,
                    title,
                    linkURL,
                    position: index + 1,
                  },
                  index
                )
              }
              data-testid="container-banner-grid"
            >
              <span className="absolute top-0 block w-full h-full rounded-2xl bg-linearCard z-10" />
              <Image
                loading="lazy"
                src={image.mobile}
                title={title.text}
                data-id={title.text}
                alt={image.altImage}
                width={
                  index === 0
                    ? classBannerSelect.mainBanner.imageDimension.mobile.width
                    : classBannerSelect.lastBanner.imageDimension.mobile.width
                }
                height={
                  index === 0
                    ? classBannerSelect.mainBanner.imageDimension.mobile.height
                    : classBannerSelect.lastBanner.imageDimension.mobile.height
                }
                className={`object-cover absolute top-0 left-0 w-full h-fit ${
                  (index === bannerSelect.length - 1 ||
                    index === bannerSelect.length - 2) &&
                  type !== 'Banner Full' &&
                  type !== 'Banner Duplo'
                    ? 'max-h-fit'
                    : 'max-h-full'
                }  rounded-md restructure-tablet:hidden`}
              />
              <Image
                loading="lazy"
                data-banner-type={classBannerSelect.dataBannerType}
                src={image.tablet}
                title={title.text}
                data-id={title.text}
                alt={image.altImage}
                width={
                  index === 0
                    ? classBannerSelect.mainBanner.imageDimension.tablet.width
                    : classBannerSelect.lastBanner.imageDimension.tablet.width
                }
                height={
                  index === 0
                    ? classBannerSelect.mainBanner.imageDimension.tablet.height
                    : classBannerSelect.lastBanner.imageDimension.tablet.height
                }
                className="object-cover absolute top-0 left-0 w-full h-full rounded-md hidden restructure-tablet:block restructure-small-desktop:hidden"
              />
              <Image
                loading="lazy"
                src={image.desktop}
                title={title.text}
                data-id={title.text}
                alt={image.altImage}
                width={
                  index === 0
                    ? classBannerSelect.mainBanner.imageDimension.desktop.width
                    : classBannerSelect.lastBanner.imageDimension.desktop.width
                }
                height={
                  index === 0
                    ? classBannerSelect.mainBanner.imageDimension.desktop.height
                    : classBannerSelect.lastBanner.imageDimension.desktop.height
                }
                className="object-cover absolute top-0 left-0 w-full h-full rounded-md hidden restructure-small-desktop:block"
              />
              <div
                className={classesnames(
                  'relative z-20 restructure-tablet:gap-xl restructure-small-desktop:gap-xxxl flex items-end justify-between w-full',
                  { [`${classBannerSelect.mainBanner.padding}`]: index === 0 },
                  { [`${classBannerSelect.lastBanner.padding}`]: index > 0 }
                )}
              >
                {title.text !== '' && (
                  <h3
                    data-testid="tittle-banner-grid"
                    className={classesnames(
                      token.text[title.textColor],
                      { [`${classBannerSelect.mainBanner.text}`]: index === 0 },
                      { [`${classBannerSelect.lastBanner.text}`]: index > 0 }
                    )}
                  >
                    {title.text}
                  </h3>
                )}

                <button
                  className={classesnames(
                    'h-[32px] min-w-[32px] restructure-small-desktop:min-w-[48px] restructure-small-desktop:h-[48px] rounded-round border border-restructure-border-primary items-center justify-center gap-xs w-[32px] hidden restructure-tablet:flex'
                  )}
                  data-testid="button-banner-grid"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.25 12C4.25 11.5858 4.58579 11.25 5 11.25H19C19.4142 11.25 19.75 11.5858 19.75 12C19.75 12.4142 19.4142 12.75 19 12.75H5C4.58579 12.75 4.25 12.4142 4.25 12Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.4697 4.46967C11.7626 4.17678 12.2374 4.17678 12.5303 4.46967L19.5303 11.4697C19.8232 11.7626 19.8232 12.2374 19.5303 12.5303L12.5303 19.5303C12.2374 19.8232 11.7626 19.8232 11.4697 19.5303C11.1768 19.2374 11.1768 18.7626 11.4697 18.4697L17.9393 12L11.4697 5.53033C11.1768 5.23744 11.1768 4.76256 11.4697 4.46967Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
            </Link>
          </InView>
        ))}
      </Container>
    </div>
  )
}
